@import url(https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
/* .carousel img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 500px;
    max-width: none;
  } */

  /* .carousel {
    background-color: green;
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
}*/


html {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;    
}
body {
    margin:0;
    background-color: #FEFEFE;
    /* height:100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    display: flex;
    flex-direction: column;     */

    /* min-height: 100%;

    display: flex;
    flex-direction: column;     */
  }

  .offerwidth{
      width:60%!important;
      margin-right: auto;
      margin-left: auto;
      height:500px!important;
  }

#left-tabs-example-tab-first {
    border-radius: 0;
}

#left-tabs-example-tab-second {
    border-radius: 0;
}

img {
    object-fit: fill
}
.card {
    width:22%;
}
.aboutus-page{width:100%; float:left; padding:3% 0;}
.aboutus-page p{font-size:16px; font-weight:400; font-family: 'Open Sans', sans-serif; line-height:32px; padding-top:40px;}
.aboutus-page img{height:600px;}

.inner-banner {width: 100%;float: left;background: #0071b3;padding: 78px 0;}

.inner-banner h2 {font-family: 'Open Sans', sans-serif;color: #FFF;font-size: 70px;}
  
.wrapper {
    height: auto;
}


main {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;    
}
footer {
    /* clear: both;
    position: relative; */
    /* background: #ffab62; */
    margin-top: auto;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}

.helpcenter .dropdown-menu {
    width:200px!important;
}
.nav {
    transition: 0.5s;

    transition-property: none;
    z-index: 10;
}

.scrolled {
    position:fixed;
    top:0;

    width:100%;

}

.infinite-scroll-component__outerdiv {
    width:100%
}

  .card {display:inline-block;}
  
.conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .conversation-list-item:hover {
    background: #eeeef1;
    cursor: pointer;
  }
  
  .conversation-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .conversation-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
    float:left;
  }
  
  .conversation-snippet {
    font-size: 14px;
    color: #888;
    margin: 0;
  }

.messager-list-header{    
    border: none;
    /* background-color: hsla(0,0%,100%,.8); */
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}



  /* .footer {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    height:100px;
 } */

 .font-size-card { font-size:0.8em}

ul {
    margin: 0;
    /* padding: 0; */
    overflow: hidden;
    list-style-type: none;
    display: flex;
    flex-direction: row;    
    margin-top:5px;
    padding-top:10px;
  }
  
  li {
    float: left;
    border-right:1px;
    border-right-style: solid; 
    padding-right:4px;
    text-align: center;
    line-height: 20px;
    /* margin-top:1px; */
    color: white;
    font-size:0.9em;
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    /* padding: 16px; */
    text-decoration: none;
  }
  
  li a:hover {
    background-color:#012305;
    color:white!important;
  }

  ul.circle{padding:none!important;}
  ul.circle, ul.circle > li { color:black ; border:none; list-style-type: circle!important; float: none; display: list-item; text-align: left; }

  
  ol.number, ol.number > li { color:black ; border:none; float: none;  text-align: left; }

  ul.ul_footer {
    list-style-type: none!important;
    font-family:Trebuchet MS; font-size: 21px;
    display: list-item!important;
    margin:10px;
    padding: 15px!important;
  }

  ul.ul_footer > li a {
    padding: 16px!important;
  }

.green {
    background-color:#012305;
}

.style-text-box {
    border-radius: 30px;
    height:30px;
    text-align: center;
    font-size: 0.8em;
}

.style-signup-boxes {
    height:35px;
    width:350px;
    text-align: justify;
    border-bottom-right-radius:10px;
    border-top-right-radius:10px;
    font-size: 0.8em;
    font-weight: 600;
}

.style-signup-boxes-small {
    height:35px;
    width:285px;
    text-align: justify;
    border-bottom-right-radius:10px;
    border-top-right-radius:10px;
    font-size: 0.8em;
    font-weight: 600;
}

.bg {
    
}

.signup-form {
    background-color: #012305;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
}

.signup-button {
    width:80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fonter{
    font-size:2.5vw;
}

.fonter-md{
    font-size:2vw;
}

.fonter-sm{
    font-size:1vw;
}
.menu{
    margin-left: auto;
    margin-right: 50px;
}
.dropdown-menu {
    width:30px!important;
}
.modal {
    overflow: hidden;
}
.modal-body{
     margin:0;
     padding: 0;
     background-color: #012305;
}

.modal-content{
    border-radius: 10px;
}

#dropdown-button-drop-down{
    background-color:#012305;
    /* margin-right:150px!important; */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: white!important;    
}

#user-lang-button{
    background-color: white;
    color: black;
    height: 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 0.8em;
    border-color: white;
    margin:auto;
    width: 200px;
    padding-top: 2px;
}

.left-margin-auto {
    margin-left:auto!important;
}

.status-message {
    opacity: 0.8!important; 
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.signup-model-close {
    display: block;
    position: relative!important; z-index: 4!important;
}

.search-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  }
.zero-border {
    border-radius: 0;
}

.buy-button {
    height:30px!important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.category-image {
    height:60%;
    width: 60%;
    display: block;
}

.forhover:hover {
    background: #eeeef1;
    cursor: pointer;
  }

.image-containter {
    height: 200px;
    width:200px;
    display: inline-block;
    text-align: center;
}
.caption {
    display: block;
}

.border-green  {
    border-block-color: #012305!important;
}
/* Small devices (tablets, 768px and up) */
@media only screen and (max-width: 600px) {
    .card{
        width:80%!important;
    }
    .bg {
        width: auto;
        height:400px;
        background-color: #012305;
    } 
    .style-signup-boxes {
        height:30px;
        width:180px;
        text-align: justify;
        border-bottom-right-radius:10px;
        border-top-right-radius:10px;
        font-size: 0.6em;
        font-weight: 600;
    }
    #user-lang-button{
        background-color: white;
        color: black;
        height: 30px;
        width:200px;
        border-radius: 30px;
        text-align: center;
        font-size: 2.5vw;
        font-weight: 600;
        border-color: white;
    }    
    .signup-form {
        background-color: #012305;
        width: 280px;
        padding-bottom: 10px;
        border-radius: 10px;
        margin: auto;
    }

    .fonter{
        font-size:4vw;
    }
    
    .fonter-md{
        font-size:3vw;
    }
    
    .fonter-sm{
        font-size:2vw;
    }
    .forgetpassword{
        margin-left:auto!important;
    }
    .submitButton{
        margin-right:auto!important;
    }
    .form-signin{
        margin-right:auto!important;
        display: flex;
        margin-top: 15px!important;
        font-size:2vw!important;
    }
    .menu{
        width:100%;
    }
    .style-text-box{
        width:100%;
    }
    .forgetpassword{
        width:100%!important;
    }    
    .small-screen {
        width:100%;
    }
    .buy-button {
        height:20px!important;
        width: -webkit-fill-available;
        margin-left: 0!important;
        font-size: 1.5vh;
        margin-right: 1px!important;
        text-align: center;
    }    
    .image-containter {

        display: inline-block;
        text-align: center;
    }
    .category-image {
        height:80px;
        width: 80px;
        margin:auto;
        border-radius: 50%;
    }

    
 }

.slide-enter {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    z-index: 1;
  }
  .slide-enter.slide-enter-active {
      opacity: 1;
      transform: scale(1) translateY(0);
      transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
  }
  
  /* slide exit */
  .slide-exit {
      opacity: 1;
      transform: scale(1) translateY(0);
      
  }
  .slide-exit.slide-exit-active {
      opacity: 0;
      transform: scale(0.97) translateY(5px);
      transition: opacity 1500ms linear, transform 1500ms ease-out;
  }
  .slide-exit-done {
    opacity: 0;
  }
