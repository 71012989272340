.slide-enter {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    z-index: 1;
  }
  .slide-enter.slide-enter-active {
      opacity: 1;
      transform: scale(1) translateY(0);
      transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
  }
  
  /* slide exit */
  .slide-exit {
      opacity: 1;
      transform: scale(1) translateY(0);
      
  }
  .slide-exit.slide-exit-active {
      opacity: 0;
      transform: scale(0.97) translateY(5px);
      transition: opacity 1500ms linear, transform 1500ms ease-out;
  }
  .slide-exit-done {
    opacity: 0;
  }